import {AbstractCommand} from './abstract-command';

export class CostruttoreCommand extends AbstractCommand {
    nome: string;

    constructor(nome: string) {
        super();
        this.nome = nome;
    }
}

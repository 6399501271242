import {AbstractCommand} from './abstract-command';

export class TestCommand extends AbstractCommand {
    ordineId: number;
    sezioneConduttore: number;
    diametroIsolante: number;
    micrografia: boolean;
    capabilityAltezza: boolean;
    capabilityForza: boolean;
}

import {AbstractCommand} from './abstract-command';

export class TerminaleCommand extends AbstractCommand {
    codice: string;
    costruttoreId: number;

    constructor(codice: string, costruttoreId: number) {
        super();
        this.codice = codice;
        this.costruttoreId = costruttoreId;
    }
}

import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Operatore} from '../model/query/operatore';

@Injectable()
export class OperatoriService extends BaseService<Operatore> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('operatori');
    }
}

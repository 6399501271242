import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ClarityModule} from '@clr/angular';
import {CoreModule} from '../core/core.module';
import {DialogComponent} from './dialog/dialog.component';
import {HeaderComponent} from './layout/header/header.component';
import {SidebarComponent} from './layout/sidebar/sidebar.component';
import {MainComponent} from './layout/main/main.component';
import {AmministratoriComponent} from './amministratori/amministratori.component';
import {AmministratoreDetailComponent} from './amministratori/amministratore-detail/amministratore-detail.component';
import {OperatoriComponent} from './operatori/operatori.component';
import {OperatoreDetailComponent} from './operatori/operatore-detail/operatore-detail.component';
import {ClientiComponent} from './clienti/clienti.component';
import {ClienteDetailComponent} from './clienti/cliente-detail/cliente-detail.component';
import {CostruttoriComponent} from './costruttori/costruttori.component';
import {CostruttoreDetailComponent} from './costruttori/costruttore-detail/costruttore-detail.component';
import {ApplicatoriComponent} from './applicatori/applicatori.component';
import {ApplicatoreDetailComponent} from './applicatori/applicatore-detail/applicatore-detail.component';
import {ModelloApplicatoreFilterComponent} from './applicatori/modello-applicatore.filter.component';
import {TipoApplicatoreFilterComponent} from './applicatori/tipo-applicatore.filter.component';
import {TerminaliComponent} from './terminali/terminali.component';
import {TerminaleDetailComponent} from './terminali/terminale-detail/terminale-detail.component';
import {OrdiniComponent} from './ordini/ordini.component';
import {OrdineDetailComponent} from './ordini/ordine-detail/ordine-detail.component';
import {SharedModule} from '../shared/shared.module';
import {ReplaceUnderscorePipe} from './common/replace-underscore.pipe';
import {DateFilterComponent} from './date.filter.component';
import {BooleanFilterComponent} from './boolean.filter.component';
import {PasswordComponent} from './password/password.component';
import {StatoOrdineFilterComponent} from './ordini/stato-ordine.filter.component';

@NgModule({
    declarations: [
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        OperatoriComponent,
        OperatoreDetailComponent,
        ClientiComponent,
        ClienteDetailComponent,
        CostruttoriComponent,
        CostruttoreDetailComponent,
        ApplicatoriComponent,
        ApplicatoreDetailComponent,
        ModelloApplicatoreFilterComponent,
        TipoApplicatoreFilterComponent,
        TerminaliComponent,
        TerminaleDetailComponent,
        OrdiniComponent,
        OrdineDetailComponent,
        ReplaceUnderscorePipe,
        DateFilterComponent,
        BooleanFilterComponent,
        StatoOrdineFilterComponent,
        PasswordComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ClarityModule,
        CoreModule,
        SharedModule
    ],
    exports: [
        CoreModule,
        SharedModule,
        DialogComponent,
        HeaderComponent,
        SidebarComponent,
        MainComponent,
        AmministratoriComponent,
        AmministratoreDetailComponent,
        OperatoriComponent,
        OperatoreDetailComponent,
        ClientiComponent,
        ClienteDetailComponent,
        CostruttoriComponent,
        CostruttoreDetailComponent,
        ApplicatoriComponent,
        ApplicatoreDetailComponent,
        ModelloApplicatoreFilterComponent,
        TipoApplicatoreFilterComponent,
        TerminaliComponent,
        TerminaleDetailComponent,
        OrdiniComponent,
        OrdineDetailComponent,
        DateFilterComponent,
        BooleanFilterComponent,
        StatoOrdineFilterComponent,
        PasswordComponent
    ]
})
export class UiModule {
}

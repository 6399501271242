import {AbstractCommand} from './abstract-command';
import {TipoIndirizzo} from '../tipo-indirizzo';

export class IndirizzoCommand extends AbstractCommand {
    tipoIndirizzo: TipoIndirizzo;
    indirizzo: string;
    citta: string;
    provincia: string;
    cap: string;
    telefono: string;
    email: string;
    nazioneId: number;
    clienteId: number;
}

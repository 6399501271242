import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {UtilsService} from '../core/utils.service';

@Component({
    selector: 'app-date-filter',
    templateUrl: './date.filter.component.html'
})
export class DateFilterComponent implements ClrDatagridFilterInterface<any> {
    state: {
    };
    @Input('attribute') attribute: string;
    
    constructor(private filterContainer: ClrDatagridFilter, private utilsService: UtilsService) {
        filterContainer.setFilter(this);
    }
    
    accepts(transaction: any) {
        return false;
    }

    changes = new Subject<any>();
    
    isActive(): boolean {
        return true;
    }

    onDateChange(d: Date): void {
        if (d) {
            this.state = {
                property: this.attribute,
                value: this.utilsService.dateToFilter(d)
            }
        } else {
            this.state = {};
        }
        this.changes.next();
    }
}
import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse, HttpParams} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Terminale} from '../model/query/terminale';
import {MisuraTerminale} from '../model/query/misura-terminale';
import {ClienteCodiceTerminale} from '../model/query/cliente-codice-terminale';
import {ClienteCodiceTerminaleCommand} from '../model/command/cliente-codice-terminale-command';
import {MisureDto} from '../model/command/misure-dto';
import {GetAllRequest} from '../model/get-all-request';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class TerminaliService extends BaseService<Terminale> {
    private storageBasePath: string = environment.storageUrl;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('terminali');
    }

    addClienteCodiceTerminale(command: ClienteCodiceTerminaleCommand): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/codice-interno', command, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>) => of(this.extractIdFromLocation(res))),
            catchError(this.handleError(this.apiUrl + ' - codice-interno', null))
        );
    }

    getList(): Observable<QueryResult<Terminale>> {
        return this.httpClient.get<QueryResult<Terminale>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }

    getCodiciInterni(id: string, 
        page: number,
        size: number,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>,
        sort: string,
        sortType: string
    ): Observable<QueryResult<ClienteCodiceTerminale>> {
        return this.httpClient.post<QueryResult<ClienteCodiceTerminale>>(this.apiUrl + '/' + id + '/codici-interni', new GetAllRequest(fields, operators, values, sort, sortType, page, size)).pipe(
            catchError(this.handleError(this.apiUrl + ' - codici-interni', null))
        );
    }

    getClienti(id: string): Observable<Array<string>> {
        return this.httpClient.get<Array<string>>(this.apiUrl + '/' + id + '/clienti').pipe(
            catchError(this.handleError(this.apiUrl + ' - clienti', null))
        );
    }

    getIdByCodice(codice: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + '/get-id-by-codice/' + codice).pipe(
            catchError(this.handleError(this.apiUrl + ' - get-id-by-codice', null))
        );
    }
    
    getMisure(id: string): Observable<Array<MisuraTerminale>> {
        return this.httpClient.get<Array<MisuraTerminale>>(this.apiUrl + '/' + id + '/misure').pipe(
            catchError(this.handleError(this.apiUrl + ' - get misure', null))
        );
    }

    addMisure(id: string, misure: MisureDto) : Observable<any> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/misure', misure).pipe(
            catchError(this.handleError(this.apiUrl + ' - add misure', null))
        );
    }

    aggiungiDocumento(id: string, tipo: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/documento', {fileName: fileName, tipo: tipo}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<Terminale>(this.apiUrl + '/' + id);
            }),
            switchMap((terminale: Terminale) => {
                if (terminale != null) {
                    return this.httpClient.put(this.storageBasePath + terminale["file" + tipo + "Url"], file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    getDocumentDownloadUrl(id: string, tipo: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/download-url/' + tipo).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentDownloadUrl', null))
        );
    }
}

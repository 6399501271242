import {environment} from '../../environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Test} from '../model/query/test';
import {QueryResult} from '../model/query/query-result';
import {ResultUrl} from '../model/result-url';

@Injectable()
export class TestService extends BaseService<Test> {
    private storageBasePath: string = environment.storageUrl;
    
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('test');
    }

    aggiungiDocumento(id: string, tipo: string, file: File, fileName: string): Observable<number> {
        return this.httpClient.post(this.apiUrl + '/' + id + '/documento', {fileName: fileName, tipo: tipo}, {observe: 'response'}).pipe(
            switchMap((res: HttpResponse<any>): Observable<any> => {
                return this.httpClient.get<Test>(this.apiUrl + '/' + id);
            }),
            switchMap((test: Test) => {
                if (test != null) {
                    return this.httpClient.put(this.storageBasePath + test["file" + tipo + "Url"], file);
                } else {
                    return of(null);
                }
            }),
            switchMap(() => {
                return of(id);
            }),
            catchError(this.handleError(' - aggiungiDocumento', null))
        );
    }

    getDocumentDownloadUrl(id: string, tipo: string): Observable<string> {
        return this.httpClient.get<ResultUrl>(this.apiUrl + '/' + id + '/download-url/' + tipo).pipe(
            switchMap((result: ResultUrl) => {
                return of(environment.storageUrl + result.url);
            }),
            catchError(this.handleError(this.apiUrl + ' - getDocumentDownloadUrl', null))
        );
    }
}

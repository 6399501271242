import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './core/auth.service';
import {ClrCommonStringsService} from '@clr/angular';
import {itLocale} from './italiano';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    constructor(private router: Router, private authService: AuthService, commonStrings: ClrCommonStringsService) {
        commonStrings.localize(itLocale);
    }
}

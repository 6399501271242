import {AbstractCommand} from './abstract-command';

export class ClienteCodiceTerminaleCommand extends AbstractCommand {
    codice: string;
    terminaleId: number;
    clienteId: number;
    
    constructor(codice: string, terminaleId: number, clienteId: number) {
        super();
        this.codice = codice;
        this.terminaleId = terminaleId;
        this.clienteId = clienteId;
    }
}

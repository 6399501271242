import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Cliente} from '../model/query/cliente';
import {ClienteCodiceTerminale} from '../model/query/cliente-codice-terminale';

@Injectable()
export class ClientiService extends BaseService<Cliente> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('clienti');
    }

    public getCodiceTerminale(id: string, terminaleId: string): Observable<ClienteCodiceTerminale> {
        return this.httpClient.get<ClienteCodiceTerminale>(this.apiUrl + '/' + id + '/terminali/' + terminaleId).pipe(
            catchError(this.handleError(this.apiUrl + ' - terminali', null))
        );
    }
}

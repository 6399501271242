import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Amministratore} from '../model/query/amministratore';

@Injectable()
export class AmministratoriService extends BaseService<Amministratore> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('amministratori');
    }
}

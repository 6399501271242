import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Ordine} from '../model/query/ordine';

@Injectable()
export class OrdiniService extends BaseService<Ordine> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('ordini');
    }

    getAllByCostruttore(costruttore: string, page: number, size: number): Observable<Array<Ordine>> {
        const params = new HttpParams()
            .set('page', page.toString() ? page.toString() : '0')
            .set('size', size.toString() ? size.toString() : '100000000');
            
        return this.httpClient.get<Array<Ordine>>(this.apiUrl + '/get-by-costruttore/' + costruttore, {params: params}).pipe(
            catchError(this.handleError(this.apiUrl + ' - get-by-costruttore', null))
        );
    }
}

import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {ClrDatagridStateInterface} from '@clr/angular';
import {QueryResult} from '../../model/query/query-result';
import {DatagridComponent} from '../common/datagrid.component';
import {Applicatore} from '../../model/query/applicatore';
import {Router} from '@angular/router';
import {ApplicatoriService} from '../../core/applicatori.service';
import {UtilsService} from '../../core/utils.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-applicatori',
    templateUrl: './applicatori.component.html',
    styleUrls: ['./applicatori.component.css']
})
export class ApplicatoriComponent extends DatagridComponent<Applicatore> {
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    specificheAggraffaturaDescription: any;
    tipoDisposizioneDescription: any;
    tipoAggraffaturaDescription: any;
    
    constructor(
        private applicatoriService: ApplicatoriService,
        public utilsService: UtilsService,
        dialog: DialogService,
        router: Router
    ) {
        super(applicatoriService, router, dialog);
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.specificheAggraffaturaDescription = utilsService.getSpecificheAggraffaturaDescription();
        this.tipoDisposizioneDescription = utilsService.getTipoDisposizioneDescription();
        this.tipoAggraffaturaDescription = utilsService.getTipoAggraffaturaDescription();
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<Applicatore>> {
        let i = fields.indexOf('tipoApplicatore');

        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        i = fields.indexOf('modelloApplicatore');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        return super.fetchData(state, fields, operators, values);
    }

    protected getEditUrl(): string {
        return  '/applicatori/';
    }
    
    onApriTerminale(id: number): void {
        this.router.navigate(['/terminali/' + id]);
    }
    
    onApriOrdine(id: number): void {
        this.router.navigate(['/ordini/' + id]);
    }
}

import {AbstractCommand} from './abstract-command';

export class ComponenteCommand extends AbstractCommand {
    nome: string;
    codice: string;

    constructor(nome: string, codice: string) {
        super();
        this.nome = nome;
        this.codice = codice;
    }
}

import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Costruttore} from '../../model/query/costruttore';
import {Router} from '@angular/router';
import {CostruttoriService} from '../../core/costruttori.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-costruttori',
    templateUrl: './costruttori.component.html',
    styleUrls: ['./costruttori.component.css']
})
export class CostruttoriComponent extends DatagridComponent<Costruttore> {

    constructor(
        private costruttoriService: CostruttoriService,
        dialog: DialogService,
        router: Router
    ) {
        super(costruttoriService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/costruttori/';
    }

}

import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { DatagridComponent } from '../common/datagrid.component';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../model/query/query-result';
import { Ordine } from '../../model/query/ordine';
import { StatoOrdine } from '../../model/stato-ordine';
import { Router } from '@angular/router';
import { OrdiniService } from '../../core/ordini.service';
import { ApplicatoriService } from '../../core/applicatori.service';
import { TerminaliService } from '../../core/terminali.service';
import { DialogService } from '../../ui/dialog';

@Component({
    selector: 'app-ordini',
    templateUrl: './ordini.component.html',
    styleUrls: ['./ordini.component.css']
})
export class OrdiniComponent extends DatagridComponent<Ordine> {
    readonly STATO_ORDINE: typeof StatoOrdine = StatoOrdine;

    constructor(
        private ordiniService: OrdiniService,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        dialog: DialogService,
        router: Router
    ) {
        super(ordiniService, router, dialog);
    }


    protected getEditUrl(): string {
        return '/ordini/';
    }

    protected fetchData(state: ClrDatagridStateInterface, fields: Array<string>, operators: Array<string>,
        values: Array<any>): Observable<QueryResult<Ordine>> {
        let i = fields.indexOf('dataEmissione');

        if (i != -1) {
            operators[i] = 'eq';
            values[i] = values[i].substring(1, values[i].length - 1);
        }
        i = fields.indexOf('stato');
        if (i != -1) {
            operators[i] = 'eq';
            values[i] = parseInt(values[i].substring(1, values[i].length - 1));
        }
        if (!state.sort) {
            state.sort = { 'by': 'dataEmissione', 'reverse': true };
        }
        return super.fetchData(state, fields, operators, values);
    }
    
    onApriCliente(id: number): void {
        this.router.navigate(['/clienti/' + id]);
    }
    
    onApriApplicatore(applicatore: string): void {
        this.applicatoriService.getIdByCodice(applicatore).subscribe(id => {
            this.router.navigate(['/applicatori/' + id]);
        });
    }
    
    onApriTerminale(terminale: string): void {
        this.terminaliService.getIdByCodice(terminale).subscribe(id => {
            this.router.navigate(['/terminali/' + id]);
        });
    }
}

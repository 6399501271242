import {AbstractCommand} from './abstract-command';
import {ModelloApplicatore} from '../modello-applicatore';
import {TipoApplicatore} from '../tipo-applicatore';
import {SpecificheAggraffatura} from '../specifiche-aggraffatura';
import {TipoDisposizione} from '../tipo-disposizione';
import {TipoAggraffatura} from '../tipo-aggraffatura';

export class ApplicatoreCommand extends AbstractCommand {
    codice: string;
    terminaleId: number;
    modelloApplicatore: ModelloApplicatore;
    tipoApplicatore: TipoApplicatore;
    modelloPressa: string;
    marcaPressa: string;
    altezzaTaraturaPressa: number;
    corsaPressa: number;
    specificheAggraffatura: SpecificheAggraffatura;
    contaCicli: boolean;
    ghieraCentesimale: boolean;
    taglioBandella: boolean;
    aggraffaturaGommino: boolean;
    terminaleIncudine: boolean;
    disposizioneDoppioCavo: TipoDisposizione;
    aggraffaturaConduttore: TipoAggraffatura;
    aggraffaturaIsolante: TipoAggraffatura;

    constructor(codice: string, 
        terminaleId: number,
        modelloApplicatore: ModelloApplicatore,
        tipoApplicatore: TipoApplicatore,
        modelloPressa: string,
        marcaPressa: string,
        altezzaTaraturaPressa: number,
        corsaPressa: number,
        specificheAggraffatura: SpecificheAggraffatura,
        contaCicli: boolean,
        ghieraCentesimale: boolean,
        taglioBandella: boolean,
        aggraffaturaGommino: boolean,
        terminaleIncudine: boolean,
        disposizioneDoppioCavo: TipoDisposizione,
        aggraffaturaConduttore: TipoAggraffatura,
        aggraffaturaIsolante: TipoAggraffatura) {
        super();
        this.codice = codice;
        this.terminaleId = terminaleId;
        this.modelloApplicatore= modelloApplicatore;
        this.tipoApplicatore= tipoApplicatore;
        this.modelloPressa= modelloPressa;
        this.marcaPressa= marcaPressa;
        this.altezzaTaraturaPressa= altezzaTaraturaPressa;
        this.corsaPressa= corsaPressa;
        this.specificheAggraffatura= specificheAggraffatura;
        this.contaCicli= contaCicli;
        this.ghieraCentesimale= ghieraCentesimale;
        this.taglioBandella= taglioBandella;
        this.aggraffaturaGommino= aggraffaturaGommino;
        this.terminaleIncudine= terminaleIncudine;
        this.disposizioneDoppioCavo= disposizioneDoppioCavo;
        this.aggraffaturaConduttore= aggraffaturaConduttore;
        this.aggraffaturaIsolante= aggraffaturaIsolante;
    }
}

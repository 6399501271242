import {Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ClrLoadingState} from '@clr/angular';
import {QueryResult} from '../../../model/query/query-result';
import {HttpResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {CostruttoriService} from '../../../core/costruttori.service';
import {OrdiniService} from '../../../core/ordini.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import {TerminaliService} from '../../../core/terminali.service';
import {Costruttore} from '../../../model/query/costruttore';
import {Ordine} from '../../../model/query/ordine';
import {CostruttoreCommand} from '../../../model/command/costruttore-command';
import {Action} from '../../../model/action';

@Component({
    selector: 'app-costruttore-detail',
    templateUrl: './costruttore-detail.component.html',
    styleUrls: ['./costruttore-detail.component.css']
})
export class CostruttoreDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    costruttore: Costruttore;
    ordini: Array<Ordine> = new Array<Ordine>();
    totalOrdini: number = 0;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private costruttoriService: CostruttoriService,
        private ordiniService: OrdiniService,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            this.costruttoriService.get(this.id).subscribe(entity => {
                this.costruttore = entity;
                this.initializeFormFields(entity);
                if (this.action != null && this.action == Action.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    refreshOrdini(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            fields.push('costruttore');
            operators.push('eq');
            values.push(this.costruttore.nome);
            this.ordiniService.getAll(state.page.current ? state.page.current - 1 : 0, 
                state.page.size ? state.page.size : 10,
                fields, operators, values, null,null).subscribe((result: QueryResult<Ordine>) => {
                this.ordini = result.objects;
                this.totalOrdini = result.count;
            });
        }
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: CostruttoreCommand = new CostruttoreCommand(formValue.nome);
        if (!this.id || this.id === 'new') {
            this.costruttoriService.create(command).subscribe(res => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (res) {
                    this.alertMessage = 'Costruttore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.costruttoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Costruttore aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            nome: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Costruttore): void {
        let formValues;

        if (entity) {
            formValues = {
                nome: entity.nome
            };
        } else {
            formValues = {
                nome: ''
            };
        }
        this.form.setValue(formValues);
    }
    
    onApriApplicatore(applicatore: string): void {
        this.applicatoriService.getIdByCodice(applicatore).subscribe(id => {
            this.router.navigate(['/applicatori/' + id]);
        });
    }
    
    onApriTerminale(terminale: string): void {
        this.terminaliService.getIdByCodice(terminale).subscribe(id => {
            this.router.navigate(['/terminali/' + id]);
        });
    }
}

import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
    readonly DEFAULT_LANGUAGE_ID: number = 2;
    readonly DECIMAL_QUANTITY_PATTERN: string = '^[0-9]+(\.[0-9]{1,3})?$';
    readonly INTEGER_PATTERN: string = '^[0-9]+$';
    readonly INTEGER_QUANTITY_PATTERN: string = '^[0-9]+$';
    readonly MOBILE_CODE_PATTERN: string = '^[0-9\-]+$';
    readonly NO_SPACES_ONLY: string = '(?!^ +$)^.+$';
    readonly FISCAL_CODE: string = '^[A-Z0-9]{16}$';
    readonly DATE_LITTLE_ENDIAN: string = '^(((0[1-9]|[12][0-9]|3[01])[- /.](0[13578]|1[02])|(0[1-9]|[12][0-9]|30)[- /.](0[469]|11)|(0[1-9]|1\\d|2[0-8])[- /.]02)[- /.]\\d{4}|29[- /.]02[- /.](\\d{2}(0[48]|[2468][048]|[13579][26])|([02468][048]|[1359][26])00))$';
    readonly DOC_SPECIFICHE: string = "Specifiche";
    readonly DOC_MICROGRAFIA: string = "Micrografia";
    readonly DOC_CAPABILITY_ALTEZZA: string = "CapabilityAltezza";
    readonly DOC_CAPABILITY_FORZA: string = "CapabilityForza";
    readonly DOC_MANUALE: string = "Manuale";
    readonly DOC_ORDER_FORM: string = "OrderForm";
    readonly DOC_MICROGRAFIE_INTERNE: string = "MicrografieInterne";
    readonly DOC_MICROGRAFIE_CLIENTE: string = "MicrografieCliente";
    readonly DOC_CERTIFICATO_COLLAUDO: string = "CertificatoCollaudo";
}

import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {BaseService} from './base.service';
import {Pressa} from '../model/query/pressa';
import {QueryResult} from '../model/query/query-result';

@Injectable()
export class PresseService extends BaseService<Pressa> {
    constructor(httpClient: HttpClient, router: Router) {
        super(httpClient, router);
        this.setApiUrl('presse');
    }

    getList(): Observable<QueryResult<Pressa>> {
        return this.httpClient.get<QueryResult<Pressa>>(this.apiUrl).pipe(
            catchError(this.handleError(this.apiUrl + ' - get', null))
        );
    }
}

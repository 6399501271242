import {AbstractBean} from './abstract-bean';
import {Misura} from './misura';
import {Terminale} from './terminale';

export class MisuraTerminale extends AbstractBean {
    valore: number;
    misura: Misura;
    misuraForeignKey: number;
    terminale: Terminale;
    terminaleForeignKey: number;
}

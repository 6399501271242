import {AbstractCommand} from './abstract-command';
import {TipoApplicatore} from '../tipo-applicatore';
import {ModelloApplicatore} from '../modello-applicatore';
import {SpecificheAggraffatura} from '../specifiche-aggraffatura';
import {TipoDisposizione} from '../tipo-disposizione';
import {TipoAggraffatura} from '../tipo-aggraffatura';
import {MaterialeOrdine} from '../materiale-ordine';

export class OrdineCommand extends AbstractCommand {
    clienteId: number;
    dataEmissione: Date;
    numeroOrdine: string;
    referente: string;
    costruttore: string;
    terminale: string;
    codiceInternoCliente: string;
    applicatore: string;
    nuovo: boolean;
    tipoApplicatore: TipoApplicatore;
    modelloApplicatore: ModelloApplicatore;
    modelloPressa: string;
    marcaPressa: string;
    altezzaTaraturaPressa: number;
    corsaPressa: number;
    specificheAggraffatura: SpecificheAggraffatura;
    contaCicli: boolean;
    ghieraCentesimale: boolean;
    taglioBandella: boolean;
    aggraffaturaGommino: boolean;
    terminaleIncudine: boolean;
    disposizioneDoppioCavo: TipoDisposizione;
    aggraffaturaConduttore: TipoAggraffatura;
    aggraffaturaIsolante: TipoAggraffatura;
    note: string;
    materialeOrdine: MaterialeOrdine;

    constructor(clienteId: number,
        dataEmissione: Date,
        numeroOrdine: string,
        referente: string,
        costruttore: string,
        terminale: string,
        codiceInternoCliente: string,
        applicatore: string,
        nuovo: boolean,
        tipoApplicatore: TipoApplicatore,
        modelloApplicatore: ModelloApplicatore,
        modelloPressa: string,
        marcaPressa: string,
        altezzaTaraturaPressa: number,
        corsaPressa: number,
        specificheAggraffatura: SpecificheAggraffatura,
        contaCicli: boolean,
        ghieraCentesimale: boolean,
        taglioBandella: boolean,
        aggraffaturaGommino: boolean,
        terminaleIncudine: boolean,
        disposizioneDoppioCavo: TipoDisposizione,
        aggraffaturaConduttore: TipoAggraffatura,
        aggraffaturaIsolante: TipoAggraffatura,
        note: string,
        materialeOrdine: MaterialeOrdine) {
        super();
        this.clienteId = clienteId;
        this.dataEmissione = dataEmissione;
        this.numeroOrdine = numeroOrdine;
        this.referente = referente;
        this.costruttore = costruttore;
        this.terminale = terminale;
        this.codiceInternoCliente = codiceInternoCliente;
        this.applicatore = applicatore;
        this.nuovo = nuovo;
        this.tipoApplicatore = tipoApplicatore;
        this.modelloApplicatore = modelloApplicatore;
        this.modelloPressa = modelloPressa;
        this.marcaPressa = marcaPressa;
        this.altezzaTaraturaPressa = altezzaTaraturaPressa;
        this.corsaPressa = corsaPressa;
        this.specificheAggraffatura = specificheAggraffatura;
        this.contaCicli = contaCicli;
        this.ghieraCentesimale = ghieraCentesimale;
        this.taglioBandella = taglioBandella;
        this.aggraffaturaGommino = aggraffaturaGommino;
        this.terminaleIncudine = terminaleIncudine;
        this.disposizioneDoppioCavo = disposizioneDoppioCavo;
        this.aggraffaturaConduttore = aggraffaturaConduttore;
        this.aggraffaturaIsolante = aggraffaturaIsolante;
        this.note = note;
        this.materialeOrdine = materialeOrdine;
    }
}

import {Component} from '@angular/core';
import {DatagridComponent} from '../common/datagrid.component';
import {Terminale} from '../../model/query/terminale';
import {Router} from '@angular/router';
import {TerminaliService} from '../../core/terminali.service';
import {DialogService} from '../../ui/dialog';

@Component({
    selector: 'app-terminali',
    templateUrl: './terminali.component.html',
    styleUrls: ['./terminali.component.css']
})
export class TerminaliComponent extends DatagridComponent<Terminale> {

    constructor(
        private terminaliService: TerminaliService,
        dialog: DialogService,
        router: Router
    ) {
        super(terminaliService, router, dialog);
    }

    protected getEditUrl(): string {
        return  '/terminali/';
    }
    
    onApriCostruttore(id: number): void {
        this.router.navigate(['/costruttori/' + id]);
    }
}

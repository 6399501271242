import {Observable, forkJoin} from 'rxjs';
import {Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ClrLoadingState} from '@clr/angular';
import {QueryResult} from '../../../model/query/query-result';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import {UtilsService} from '../../../core/utils.service';
import {Applicatore} from '../../../model/query/applicatore';
import {Componente} from '../../../model/query/componente';
import {ApplicatoreCommand} from '../../../model/command/applicatore-command';
import {ComponenteCommand} from '../../../model/command/componente-command';
import {TerminaliService} from '../../../core/terminali.service';
import {NomiComponenteService} from '../../../core/nomi-componente.service';
import {Terminale} from '../../../model/query/terminale';
import {Action} from '../../../model/action';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-applicatore-detail',
    templateUrl: './applicatore-detail.component.html',
    styleUrls: ['./applicatore-detail.component.css']
})
export class ApplicatoreDetailComponent implements OnInit {
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    specificheAggraffaturaDescription: any;
    tipoDisposizioneDescription: any;
    tipoAggraffaturaDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    applicatore: Applicatore;
    terminali: Array<Terminale>;
    componenti: Array<Componente> = new Array<Componente>();
    selectedComponente: Componente;
    totalTerminali: number;
    currentTerminale: Terminale;
    selectedTerminale: Terminale;
    showSceltaTerminaleModal: boolean = false;
    fileSpecificheName: string;
    fileManualeName: string;
    fileOrderFormName: string;
    fileMicrografieInterneName: string;
    fileMicrografieClienteName: string;
    fileCertificatoCollaudoName: string;
    fileSpecifiche: File;
    fileManuale: File;
    fileOrderForm: File;
    fileMicrografieInterne: File;
    fileMicrografieCliente: File;
    fileCertificatoCollaudo: File;
    showComponenteModal: boolean = false;
    componenteModal = {
        nome: undefined,
        codice: undefined,
        add: true
    };
    nomiComponenti: Array<string>;
    availableNomiComponenti: Array<string>;
    
    constructor(
        private route: ActivatedRoute,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        private nomiComponenteService: NomiComponenteService,
        public utilsService: UtilsService,
        private dialog: DialogService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.specificheAggraffaturaDescription = utilsService.getSpecificheAggraffaturaDescription();
        this.tipoDisposizioneDescription = utilsService.getTipoDisposizioneDescription();
        this.tipoAggraffaturaDescription = utilsService.getTipoAggraffaturaDescription();
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        if (this.id !== 'new') {
            let observables: Array<Observable<any>> = [
                this.applicatoriService.get(this.id),
                this.nomiComponenteService.getList()
            ];

            forkJoin(observables).subscribe(([entity, nomi]) => {
                this.nomiComponenti = nomi.objects.map(o => o.nome);
                this.fileSpecificheName = entity.fileSpecificheName;
                this.fileManualeName = entity.fileManualeName;
                this.fileOrderFormName = entity.fileOrderFormName;
                this.fileMicrografieInterneName = entity.fileMicrografieInterneName;
                this.fileMicrografieClienteName = entity.fileMicrografieClienteName;
                this.fileCertificatoCollaudoName = entity.fileCertificatoCollaudoName;
                this.currentTerminale = entity.terminale;
                this.initializeFormFields(entity);
                if (this.action != null && this.action == Action.VIEW) {
                    this.readOnly = true;
                }
            });
        } else {
            this.initializeFormFields(null);
        }
    }

    refreshComponenti(): void {
        this.applicatoriService.getComponenti(this.id).subscribe(componenti => {
            this.componenti = componenti;
        });
    }        
    
    onNuovoComponente(): void {
        this.showComponenteModal = true;
        this.componenteModal.nome = '';
        this.componenteModal.codice = '';
        this.componenteModal.add = true;
        this.recalcAvailableNomiComponenti();
    }

    private recalcAvailableNomiComponenti(): void {
        if (this.componenteModal.add) {
            this.availableNomiComponenti = this.nomiComponenti.filter(p => this.componenti.findIndex(m2 => m2.nome == p) == -1);
        } else {
            this.availableNomiComponenti = this.nomiComponenti;
        }
    }
    
    addComponente(): void {
        let command: ComponenteCommand = new ComponenteCommand(this.componenteModal.nome, this.componenteModal.codice);
        let o: Observable<any> =  this.componenteModal.add ? this.applicatoriService.addComponente(this.id, command)
            : this.applicatoriService.putComponente(this.id, this.selectedComponente.id.toString(), command);
            
        o.subscribe(() => {
            this.refreshComponenti();
            this.showComponenteModal = false;
        });
    }

    onEditComponente(): void {
        this.showComponenteModal = true;
        this.componenteModal.nome = this.selectedComponente.nome;
        this.componenteModal.codice = this.selectedComponente.codice;
        this.componenteModal.add = false;
        this.recalcAvailableNomiComponenti();
    }

    onDeleteComponente(): void {
        this.dialog.confirm({
            title: 'Comunicazione',
            content: 'Confermi la cancellazione?',
            acceptText: 'Sì',
            cancelText: 'Annulla',
            acceptType: 'warning',
            iconShape: null
        }).subscribe((result: boolean) => {
            if (result) {
                this.applicatoriService.deleteComponente(this.id, this.selectedComponente.id.toString()).subscribe(() => {
                    this.applicatoriService.getComponenti(this.id).subscribe(componenti => {
                        this.componenti = componenti;
                    });
                });
            }
        });
    }
    
    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        
        const formValue = this.form.getRawValue();
        const command: ApplicatoreCommand = new ApplicatoreCommand(formValue.codice, 
                this.currentTerminale.id,
                formValue.modello,
                formValue.tipo,
                formValue.modelloPressa,
                formValue.marcaPressa,
                formValue.altezzaTaraturaPressa,
                formValue.corsaPressa,
                formValue.specificheAggraffatura,
                formValue.contaCicli,
                formValue.ghieraCentesimale,
                formValue.taglioBandella,
                formValue.aggraffaturaGommino,
                formValue.terminaleIncudine,
                formValue.disposizioneDoppioCavo,
                formValue.aggraffaturaConduttore,
                formValue.aggraffaturaIsolante);
                
        if (!this.id || this.id === 'new') {
            this.applicatoriService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (res) {
                    this.alertMessage = 'Applicatore creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.applicatoriService.update(this.id, command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Applicatore aggiornato!';
                    this.alertClosed = false;
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            terminale: ['', [Validators.required]],
            codice: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            modello: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            modelloPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            marcaPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            altezzaTaraturaPressa: [{ value: '' }],
            corsaPressa: [{ value: '' }],
            specificheAggraffatura: ['', [Validators.required]],
            contaCicli: [{ value: false }],
            ghieraCentesimale: [{ value: false }],
            taglioBandella: [{ value: false }],
            aggraffaturaGommino: [{ value: false }],
            terminaleIncudine: [{ value: false }],
            disposizioneDoppioCavo: ['', [Validators.required]],
            aggraffaturaConduttore: ['', [Validators.required]],
            aggraffaturaIsolante: ['', [Validators.required]]
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Applicatore): void {
        let formValues;

        if (entity) {
            formValues = {
                terminale: this.currentTerminale.codice,
                codice: entity.codice,
                modello: entity.modelloApplicatore.toString(),
                tipo: entity.tipoApplicatore.toString(),
                modelloPressa: entity.modelloPressa,
                marcaPressa: entity.marcaPressa,
                altezzaTaraturaPressa: entity.altezzaTaraturaPressa,
                corsaPressa: entity.corsaPressa,
                specificheAggraffatura: entity.specificheAggraffatura.toString(),
                contaCicli: entity.contaCicli,
                ghieraCentesimale: entity.ghieraCentesimale,
                taglioBandella: entity.taglioBandella,
                aggraffaturaGommino: entity.aggraffaturaGommino,
                terminaleIncudine: entity.terminaleIncudine,
                disposizioneDoppioCavo: entity.disposizioneDoppioCavo.toString(),
                aggraffaturaConduttore: entity.aggraffaturaConduttore.toString(),
                aggraffaturaIsolante: entity.aggraffaturaIsolante.toString()
            };
        } else {
            formValues = {
                terminale: '',
                codice: '',
                modello: '0',
                tipo: '0',
                modelloPressa: '',
                marcaPressa: '',
                altezzaTaraturaPressa: '',
                corsaPressa: '',
                specificheAggraffatura: '0',
                contaCicli: false,
                ghieraCentesimale: false,
                taglioBandella: false,
                aggraffaturaGommino: false,
                terminaleIncudine: false,
                disposizioneDoppioCavo: '0',
                aggraffaturaConduttore: '0',
                aggraffaturaIsolante: '0'
            };
        }
        this.form.setValue(formValues);
    }

    onDownloadDocumento(tipo: string) {
        this.applicatoriService.getDocumentDownloadUrl(this.id.toString(), tipo).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any, tipo: string) {
        if (tipo === this.globals.DOC_SPECIFICHE) {
            this.fileSpecifiche = event.target.files[0];
        } else if (tipo === this.globals.DOC_MANUALE) {
            this.fileManuale = event.target.files[0];
        } else if (tipo === this.globals.DOC_ORDER_FORM) {
            this.fileOrderForm = event.target.files[0];
        } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
            this.fileMicrografieInterne = event.target.files[0];
        } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
            this.fileMicrografieCliente = event.target.files[0];
        } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
            this.fileCertificatoCollaudo = event.target.files[0];
        }
    }

    uploadFile(tipo: string): void {
        let fileName = tipo + "-" + this.id;
        let file: File;
        
        if (tipo === this.globals.DOC_SPECIFICHE) {
            file = this.fileSpecifiche;
        } else if (tipo === this.globals.DOC_MANUALE) {
            file = this.fileManuale;
        } else if (tipo === this.globals.DOC_ORDER_FORM) {
            file = this.fileOrderForm;
        } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
            file = this.fileMicrografieInterne;
        } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
            file = this.fileMicrografieCliente;
        } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
            file = this.fileCertificatoCollaudo;
        }
        this.applicatoriService.aggiungiDocumento(this.id, tipo, file, fileName).subscribe(() => {
            if (tipo === this.globals.DOC_SPECIFICHE) {
                this.fileSpecificheName = fileName;
            } else if (tipo === this.globals.DOC_MANUALE) {
                this.fileManualeName = fileName;
            } else if (tipo === this.globals.DOC_ORDER_FORM) {
                this.fileOrderFormName = fileName;
            } else if (tipo === this.globals.DOC_MICROGRAFIE_INTERNE) {
                this.fileMicrografieInterneName = fileName;
            } else if (tipo === this.globals.DOC_MICROGRAFIE_CLIENTE) {
                this.fileMicrografieClienteName = fileName;
            } else if (tipo === this.globals.DOC_CERTIFICATO_COLLAUDO) {
                this.fileCertificatoCollaudoName = fileName;
            }
        });
    }

    onShowSceltaTerminaleModal(): void {
        this.showSceltaTerminaleModal = true;
    }

    onSelectTerminale(): void {
        const formValues = {
            terminale: this.selectedTerminale ? this.selectedTerminale.codice : ''
        };

        this.form.patchValue(formValues);
        this.currentTerminale = this.selectedTerminale;
        this.showSceltaTerminaleModal = false;
        this.form.markAsDirty();
    }
    
    refreshTerminali(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            this.terminaliService.getAll(
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<Terminale>) => {
                this.terminali = <Array<Terminale>> result.objects;
                this.totalTerminali = result.count;
            });
        }
    }
}

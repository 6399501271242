import { OnInit, Directive } from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {Observable, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Router} from '@angular/router';
import {QueryResult} from '../../model/query/query-result';
import {AbstractBean} from '../../model/query/abstract-bean';
import {BaseService} from '../../core/base.service';
import {DialogService} from '../../ui/dialog';

export class DatagridRequest<COMPONENT extends DatagridComponent<AbstractBean>> {
    state: ClrDatagridStateInterface;
    component: COMPONENT;

    constructor(state: ClrDatagridStateInterface, component: COMPONENT) {
        this.state = state;
        this.component = component;
    }
}

@Directive()
export abstract class DatagridComponent<ENTITY extends AbstractBean> implements OnInit {

    debouncer: Subject<any> = new Subject<any>();
    objects: ENTITY[];
    selectedObjects = new Array<ENTITY>();
    total: number;
    loading = true;
    datagridState: ClrDatagridStateInterface;
    sub: any;

    constructor(protected service: BaseService<ENTITY>, protected router: Router, private dialog: DialogService) {
    }

    ngOnInit() {
        this.sub = this.debouncer.asObservable().pipe(
            debounceTime(500)
        ).subscribe(this.manageRequest);
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    protected fetchData(
        state: ClrDatagridStateInterface,
        fields: Array<string>,
        operators: Array<string>,
        values: Array<any>
    ): Observable<QueryResult<ENTITY>> {
        return this.service.getAll(
            state.page.current ? state.page.current - 1 : 0,
            state.page.size ? state.page.size : 10,
            fields,
            operators,
            values,
            state.sort ? state.sort.by.toString() : null,
            state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
        );
    }

    refresh(state: ClrDatagridStateInterface) {
        if (state) {
            this.datagridState = state;
        }
        this.debouncer.next(new DatagridRequest(state, this));
    }

    protected manageRequest(request: DatagridRequest<this>) {
        if (request.state && request.state.page) {
            if (request.state.page.current < 0) {
                request.state.page.current = 0;
            }
            this.loading = true;

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (request.state.filters) {
                for (const filter of request.state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            request.component.fetchData(request.state, fields, operators, values).subscribe((result: QueryResult<ENTITY>) => {
                request.component.objects = result.objects;
                request.component.total = result.count;
                request.component.loading = false;
            });
        }
    }

    protected abstract getEditUrl(): string;

    protected getEditQueryParams(entity: ENTITY): any {
        return null;
    }

    protected deleteObject(id: number): Observable<any> {
        return this.service.delete(id.toString());
    }

    onEdit(entity: ENTITY): void {
        const url = this.getEditUrl() + entity.id;
        const queryParams: any = this.getEditQueryParams(entity);

        if (queryParams) {
            this.router.navigate([url], {queryParams: queryParams});
        } else {
            this.router.navigate([url]);
        }
    }

    onDelete(obj: ENTITY): void {
        this.dialog.confirm({
            title: 'Comunicazione',
            content: 'Confermi la cancellazione?',
            acceptText: 'Sì',
            cancelText: 'Annulla',
            acceptType: 'warning',
            iconShape: null
        }).subscribe((result: boolean) => {
            if (result) {
                this.deleteObject(obj.id).subscribe(x => {
                    if (!x) {
                        this.selectedObjects = new Array<any>();
                        this.dialog.info({
                            title: 'Info',
                            content: 'Eliminato!',
                        }).subscribe(() => this.refresh(this.datagridState));
                    }
                });
            }
        });
    }

}

import {AbstractCommand} from './abstract-command';

export class MisureDto extends AbstractCommand {
    ids: Array<number>;
    values: Array<number>;
    
    constructor(ids: Array<number>, values: Array<number>) {
        super();
        this.ids = ids;;
        this.values = values;
    }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ClrDatagridStateInterface } from '@clr/angular';
import { QueryResult } from '../../../model/query/query-result';
import { AbstractBean } from '../../../model/query/abstract-bean';
import { ClrLoadingState } from '@clr/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../../../core/globals.service';
import { TerminaliService } from '../../../core/terminali.service';
import { MisureService } from '../../../core/misure.service';
import { CostruttoriService } from '../../../core/costruttori.service';
import { OrdiniService } from '../../../core/ordini.service';
import { ApplicatoriService } from '../../../core/applicatori.service';
import { Costruttore } from '../../../model/query/costruttore';
import { Ordine } from '../../../model/query/ordine';
import { Terminale } from '../../../model/query/terminale';
import { Misura } from '../../../model/query/misura';
import { MisuraTerminale } from '../../../model/query/misura-terminale';
import { ClienteCodiceTerminale } from '../../../model/query/cliente-codice-terminale';
import { TerminaleCommand } from '../../../model/command/terminale-command';
import { MisureDto } from '../../../model/command/misure-dto';
import { Action } from '../../../model/action';

@Component({
    selector: 'app-terminale-detail',
    templateUrl: './terminale-detail.component.html',
    styleUrls: ['./terminale-detail.component.css']
})
export class TerminaleDetailComponent implements OnInit {
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    costruttori: Array<Costruttore>;
    codiciInterni: Array<ClienteCodiceTerminale>;
    misure: Array<MisuraTerminale>;
    clienti: Array<string> = new Array<string>();
    totalCodiciInterni: number;
    fileSpecificheName: string;
    fileSpecifiche: File;
    codice: string;
    ordini: Array<Ordine> = new Array<Ordine>();
    totalOrdini: number = 0;
    selectedMisuraTerminale: MisuraTerminale;
    showMisuraTerminaleModal: boolean = false;
    valore: number;
    calcolaValore: Map<string, Function> = new Map<string, Function>();
    @ViewChild("valoreInput") valoreField: ElementRef;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private terminaliService: TerminaliService,
        private misureService: MisureService,
        private costruttoriService: CostruttoriService,
        private ordiniService: OrdiniService,
        private applicatoriService: ApplicatoriService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.createForm();
        this.initCalcolaValore();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        this.costruttoriService.getList().subscribe(costruttori => {
            this.costruttori = costruttori.objects;
            if (this.id !== 'new') {
                let observables: Array<Observable<any>> = [
                    this.terminaliService.get(this.id),
                    this.terminaliService.getClienti(this.id)
                ];

                forkJoin(observables).subscribe(([entity, clienti]) => {
                    this.codice = entity.codice;
                    this.fileSpecificheName = entity.fileSpecificheName;
                    this.clienti = clienti;
                    this.initializeFormFields(entity);
                    if (this.action != null && this.action == Action.VIEW) {
                        this.readOnly = true;
                    }
                });
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const command: TerminaleCommand = new TerminaleCommand(formValue.codice, formValue.costruttore);
        if (!this.id || this.id === 'new') {
            this.terminaliService.create(command).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (res) {
                    this.alertMessage = 'Terminale creato!';
                    this.alertClosed = false;
                }
            });
        } else {
            this.terminaliService.update(this.id, command).subscribe((res) => {
                if (!res) {
                    this.terminaliService.addMisure(this.id, new MisureDto(this.misure.map(m => m.misura.id), this.misure.map(m => m.valore))).subscribe((res) => {
                        if (!res) {
                            this.submitButton = ClrLoadingState.DEFAULT;
                            this.alertMessage = 'Terminale aggiornato!';
                            this.alertClosed = false;
                        }
                    });
                }
            });
        }
    }

    createForm(): void {
        const formGroup = {
            codice: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            costruttore: ''
        };
        this.form = this.formBuilder.group(formGroup);
    }

    initializeFormFields(entity: Terminale): void {
        let formValues;

        if (entity) {
            formValues = {
                codice: entity.codice,
                costruttore: entity.costruttoreForeignKey ? entity.costruttoreForeignKey.toString() : ''
            };
        } else {
            formValues = {
                codice: '',
                costruttore: ''
            };
        }
        this.form.setValue(formValues);
    }

    onDownloadDocumento() {
        this.terminaliService.getDocumentDownloadUrl(this.id.toString(), this.globals.DOC_SPECIFICHE).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }

    onFileChange(event: any) {
        this.fileSpecifiche = event.target.files[0];
    }

    uploadFile(): void {
        let fileName = "Specifiche-" + this.id;

        this.terminaliService.aggiungiDocumento(this.id, this.globals.DOC_SPECIFICHE, this.fileSpecifiche, fileName).subscribe(() => {
            this.fileSpecificheName = fileName;
        });
    }

    refreshOrdini(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            fields.push('terminale');
            operators.push('eq');
            values.push(this.codice);
            this.ordiniService.getAll(state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields, operators, values, null, null).subscribe((result: QueryResult<Ordine>) => {
                    this.ordini = result.objects;
                    this.totalOrdini = result.count;
                });
        }
    }

    refreshCodiciInterni(state: ClrDatagridStateInterface): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            this.terminaliService.getCodiciInterni(this.id,
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<AbstractBean>) => {
                this.codiciInterni = <Array<ClienteCodiceTerminale>>result.objects;
                this.totalCodiciInterni = result.count;
            });
        }
    }

    refreshMisure(state: ClrDatagridStateInterface): void {
        this.terminaliService.getMisure(this.id).subscribe((result: Array<MisuraTerminale>) => {
            if (result.length > 0) {
                this.misure = result;
            } else {
                this.misureService.getList().subscribe((result: QueryResult<Misura>) => {
                    this.misure = result.objects.map(misura => {
                        let mt: MisuraTerminale = new MisuraTerminale();

                        mt.misuraForeignKey = misura.id;
                        mt.misura = misura;
                        mt.valore = 0;
                        return mt;
                    });
                });
            }
        });
    }

    onApriApplicatore(applicatore: string): void {
        this.applicatoriService.getIdByCodice(applicatore).subscribe(id => {
            this.router.navigate(['/applicatori/' + id]);
        });
    }

    onEditMisuraTerminale(): void {
        this.showMisuraTerminaleModal = true;
        this.valore = this.selectedMisuraTerminale.valore;
        setTimeout(() => {
            this.valoreField.nativeElement.focus();
        }, 0);
    }

    updateMisuraTerminale(): void {
        this.misure.find(mt => mt.misuraForeignKey == this.selectedMisuraTerminale.misuraForeignKey).valore = Number(this.valore);
        this.recalcMisure();
        this.showMisuraTerminaleModal = false;
        this.form.markAsDirty();
    }

    recalcMisure(): void {
        this.misure.filter(mt => mt.misura.calcolato).forEach(mt => {
            mt.valore = this.calcolaValore.get(mt.misura.sigla)();
        });
    }

    findValoreMisuraFromSigla(sigla: string): number {
        let m: MisuraTerminale = this.misure.find(mt => mt.misura.sigla == sigla);

        return m ? m.valore : undefined;
    }

    initCalcolaValore(): void {
        this.calcolaValore.set('CG', () => {
            return this.findValoreMisuraFromSigla('PTM') + this.findValoreMisuraFromSigla('SPTM');
        });
        this.calcolaValore.set('CR', () => {
            return this.findValoreMisuraFromSigla('BSCG') + 2 * this.findValoreMisuraFromSigla('SCR');
        });
        this.calcolaValore.set('TM', () => {
            return this.findValoreMisuraFromSigla('IG') - this.findValoreMisuraFromSigla('IR');
        });
        this.calcolaValore.set('IG', () => {
            return 2 * this.findValoreMisuraFromSigla('PTM') + 3 * this.findValoreMisuraFromSigla('SPTM');
        });
        this.calcolaValore.set('IR', () => {
            return this.findValoreMisuraFromSigla('PTM') + 4 * this.findValoreMisuraFromSigla('SPTM');
        });
    }
}

import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MeService } from './me.service';
import { TipoIndirizzo } from '../model/tipo-indirizzo';

export function FiscalCodeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value) {
            let cf: string = control.value;

            if (/^[0-9A-Z]{16}$/.test(cf)) {
                let map = [1, 0, 5, 7, 9, 13, 15, 17, 19, 21, 1, 0, 5, 7, 9, 13, 15, 17,
                    19, 21, 2, 4, 18, 20, 11, 3, 6, 8, 12, 14, 16, 10, 22, 25, 24, 23];
                let s = 0;

                for (var i = 0; i < 15; i++) {
                    let c = cf.charCodeAt(i);
                    if (c < 65)
                        c = c - 48;
                    else
                        c = c - 55;
                    if (i % 2 == 0)
                        s += map[c];
                    else
                        s += c < 10 ? c : c - 10;
                }
                return String.fromCharCode(65 + s % 26) === cf.charAt(15) ? null : { 'fiscalCodeFormat': { value: control.value } };
            } else {
                return { 'fiscalCodeFormat': { value: control.value } };
            }
        } else {
            return null;
        }
    };
}

@Injectable()
export class UtilsService {
    constructor() {
    }

    public isValidEmailAddress(emailAddress: string): boolean {
        var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);
        return pattern.test(emailAddress);
    }

    public dateToField(d: Date): string {
        return d ? moment(d).format('DD/MM/YYYY').toString() : '';
    }

    public datetimeToField(d: Date): string {
        return d ? moment(d).format('DD/MM/YYYY HH:mm').toString() : '';
    }

    public dateToFilter(d: Date): string {
        return d ? moment(d).format('YYYY-MM-DD HH:mm:SS').toString() : '';
    }

    public formatDate(d: Date, format: string): string {
        return d ? moment(d).format(format).toString() : '';
    }

    public fieldToDatetimeString(field: string): string {
        return moment.utc(field, 'DD/MM/YYYY').format(moment.HTML5_FMT.DATETIME_LOCAL_MS);
    }

    public fieldToDate(field: string): Date {
        return field ? moment.utc(field, 'DD/MM/YYYY').startOf('day').toDate() : undefined;
    }

    public fieldToDateNoUtc(field: Date): Date {
        return field ? moment(field).startOf('day').toDate() : undefined;
    }

    public dateToEpoch(thedate: Date): Date {
        thedate.setHours(0, 0, 0, 0);

        return thedate;
    }

    public datetimeLocalChanged(eventDate: string): Date | null {
        return !!eventDate ? new Date(eventDate) : null;
    }

    getUniqueLinkParameter(): string {
        return new Date().getTime().toString();
    }

    removeFromArray(obj: any, array: Array<any>) {
        const index = array.findIndex(x => x.id === obj.id);

        if (index != -1) {
            array.splice(index, 1);
        }
    }

    swapAssignment(model: {
        leftArray: Array<any>;
        leftSelectedArray: Array<any>;
        rightArray: Array<any>;
        rightSelectedArray: Array<any>
    }) {
        for (const obj of model.leftSelectedArray) {
            model.rightArray.push(obj);
            this.removeFromArray(obj, model.leftArray);
        }
        for (const obj of model.rightSelectedArray) {
            model.leftArray.push(obj);
            this.removeFromArray(obj, model.rightArray);
        }
        model.leftSelectedArray = new Array<any>();
        model.rightSelectedArray = new Array<any>();

        return model;
    }

    public getTipiIndirizzoDescription() {
        return [
            {value: TipoIndirizzo.SEDE_LEGALE, description: 'Sede legale'},
            {value: TipoIndirizzo.SEDE_PRODUTTIVA, description: 'Sede produttiva'},
            {value: TipoIndirizzo.SEDE_AMMINISTRATIVA, description: 'Sede amministrativa'}
        ];
    }

    public getModelliApplicatoreDescription() {
        return {
            0: 'Meccanico',
            1: 'Pneumatico'
        };
    }

    public getTipiApplicatoreDescription() {
        return {
            0: 'Frontale',
            1: 'Laterale sx',
            2: 'Laterale dx'
        };
    }

    public getSpecificheAggraffaturaDescription() {
        return {
            0: 'Cliente',
            1: 'Costruttore',
            2: 'Cablex'
        };
    }

    public getTipoDisposizioneDescription() {
        return {
            0: 'Orizzontale',
            1: 'Verticale'
        };
    }

    public getTipoAggraffaturaDescription() {
        return {
            0: 'F',
            1: 'Sovrapposta',
            2: 'Tonda'
        };
    }

    public getMaterialeOrdineDescription() {
        return {
            0: 'Completo',
            1: 'Incompleto',
            2: 'Non conforme'
        };
    }
}

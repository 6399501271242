import {Component, OnInit} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {QueryResult} from '../../../model/query/query-result';
import {Observable, forkJoin, of} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {ClrLoadingState} from '@clr/angular';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../../core/globals.service';
import {BaseService} from '../../../core/base.service';
import {OrdiniService} from '../../../core/ordini.service';
import {TestService} from '../../../core/test.service';
import {ClientiService} from '../../../core/clienti.service';
import {CostruttoriService} from '../../../core/costruttori.service';
import {ApplicatoriService} from '../../../core/applicatori.service';
import {TerminaliService} from '../../../core/terminali.service';
import {UtilsService} from '../../../core/utils.service';
import {AbstractBean} from '../../../model/query/abstract-bean';
import {Ordine} from '../../../model/query/ordine';
import {Cliente} from '../../../model/query/cliente';
import {ClienteDTO} from '../../../model/command/cliente-dto';
import {ClienteCommand} from '../../../model/command/cliente-command';
import {Costruttore} from '../../../model/query/costruttore';
import {CostruttoreCommand} from '../../../model/command/costruttore-command';
import {Applicatore} from '../../../model/query/applicatore';
import {ApplicatoreCommand} from '../../../model/command/applicatore-command';
import {Terminale} from '../../../model/query/terminale';
import {Test} from '../../../model/query/test';
import {TerminaleCommand} from '../../../model/command/terminale-command';
import {ClienteCodiceTerminaleCommand} from '../../../model/command/cliente-codice-terminale-command';
import {TestCommand} from '../../../model/command/test-command';
import {OrdineCommand} from '../../../model/command/ordine-command';
import {OrdineDTO} from '../../../model/command/ordine-dto';
import {Action} from '../../../model/action';
import {DialogService} from '../../dialog';

@Component({
    selector: 'app-ordine-detail',
    templateUrl: './ordine-detail.component.html',
    styleUrls: ['./ordine-detail.component.css']
})
export class OrdineDetailComponent implements OnInit {
    readonly ALTEZZA_STANDARD: number = 135.8;
    readonly CORSA_STANDARD: number = 40;
    
    modelliApplicatoreDescription: any;
    tipiApplicatoreDescription: any;
    specificheAggraffaturaDescription: any;
    tipoDisposizioneDescription: any;
    tipoAggraffaturaDescription: any;
    materialeOrdineDescription: any;
    submitButton: ClrLoadingState = ClrLoadingState.DEFAULT;
    form: FormGroup;
    selectedObjects = [];
    id: string;
    alertClosed = true;
    alertMessage: String;
    action: Action;
    readOnly = false;
    ordine: Ordine;
    clienti: Array<Cliente>;
    totalClienti: number;
    currentCliente: Cliente;
    selectedCliente: Cliente;
    costruttori: Array<Costruttore>;
    totalCostruttori: number;
    currentCostruttore: Costruttore;
    selectedCostruttore: Costruttore;
    applicatori: Array<Applicatore>;
    totalApplicatori: number;
    currentApplicatore: Applicatore;
    selectedApplicatore: Applicatore;
    terminali: Array<Terminale>;
    totalTerminali: number;
    currentTerminale: Terminale;
    selectedTerminale: Terminale;
    showSceltaClienteModal: boolean = false;
    showSceltaCostruttoreModal: boolean = false;
    showSceltaApplicatoreModal: boolean = false;
    showSceltaTerminaleModal: boolean = false;
    applicatoreOriginale: string;
    file: File;
    toDelete: Array<Number> = new Array<Number>();
    showDialogCapabilityAltezza = false;
    showDialogCapabilityForza = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private ordiniService: OrdiniService,
        private testService: TestService,
        private clientiService: ClientiService,
        private costruttoriService: CostruttoriService,
        private applicatoriService: ApplicatoriService,
        private terminaliService: TerminaliService,
        private utilsService: UtilsService,
        private dialog: DialogService,
        private formBuilder: FormBuilder,
        private globals: Globals
    ) {
        this.modelliApplicatoreDescription = utilsService.getModelliApplicatoreDescription();
        this.tipiApplicatoreDescription = utilsService.getTipiApplicatoreDescription();
        this.specificheAggraffaturaDescription = utilsService.getSpecificheAggraffaturaDescription();
        this.tipoDisposizioneDescription = utilsService.getTipoDisposizioneDescription();
        this.tipoAggraffaturaDescription = utilsService.getTipoAggraffaturaDescription();
        this.materialeOrdineDescription = utilsService.getMaterialeOrdineDescription();
        this.createForm();
    }

    ngOnInit() {
        this.id = this.route.snapshot.paramMap.get('id');
        this.route.queryParams.subscribe(params => {
            if (params) {
                if (params.action) {
                    this.action = params.action;
                }
            }
        });
        
        let observables: Array<Observable<any>> = [
            this.applicatoriService.getList()
        ];
        
        if (this.id !== 'new') {
            observables.push(this.ordiniService.get(this.id));
        }
        forkJoin(observables).subscribe(([applicatori, ordine]) => {
            this.applicatori = applicatori.objects;
            if (this.id !== 'new') {
                this.ordine = ordine;
                ordine.test.forEach(t => this.addTestField());
                this.initializeFormFields(ordine);
                if (this.action != null && this.action == Action.VIEW) {
                    this.readOnly = true;
                }
            } else {
                this.initializeFormFields(null);
            }
        });
    }

    onSubmit() {
        this.submitButton = ClrLoadingState.LOADING;
        
        const formValue = this.form.getRawValue();
        let cliente = this.clienti.find(c => c.ragioneSociale == formValue.cliente);
        let terminale = this.terminali.find(t => t.codice == formValue.terminale);
        let costruttore = this.costruttori.find(t => t.nome == formValue.costruttore);
        
        if (!cliente || !terminale || !costruttore) {
            this.dialog.confirm({
                title: 'Comunicazione',
                content: 'I seguenti dati: <br>' + (cliente ? '' : 'Cliente ' + formValue.cliente + '<br>') 
                    + (terminale ? '' : 'Terminale ' + formValue.terminale + '<br>')
                    + (costruttore ? '' : 'Costruttore ' + formValue.costruttore + '<br>') + ' non sono presenti sul sistema. Li vuoi inserire?',
                acceptText: 'Sì',
                cancelText: 'No',
                acceptType: 'warning',
                iconShape: null
            }).subscribe((result: boolean) => {
                if (result) {
                    this.saveCostruttore(costruttore, formValue.costruttore).subscribe(costruttoreId => {
                        if (!isNaN(costruttoreId)) {
                            this.saveCliente(cliente, formValue.cliente, formValue.referente).subscribe(clienteId => {
                                if (!isNaN(clienteId)) {
                                    this.saveTerminale(terminale, formValue.terminale, costruttoreId, clienteId).subscribe(terminaleId => {
                                        if (!isNaN(terminaleId)) {
                                            this.saveCodiceClienteTerminale(terminaleId, clienteId, formValue.codiceInternoCliente).subscribe(id => {
                                                if (!isNaN(id)) {
                                                    this.saveApplicatore(terminaleId, formValue).subscribe(applicatore => {
                                                        if (applicatore && this.file) {
                                                            this.uploadFileSpecifiche(applicatore.id).subscribe(() => {
                                                                this.saveOrdine(clienteId, applicatore.codice);
                                                            });
                                                        } else if (applicatore) {
                                                            this.saveOrdine(clienteId, applicatore.codice);
                                                        } else {
                                                            this.saveOrdine(clienteId, this.applicatoreOriginale);
                                                        }
                                                    });
                                                } else {
                                                    this.submitButton = ClrLoadingState.DEFAULT;
                                                }
                                            });
                                        } else {
                                            this.submitButton = ClrLoadingState.DEFAULT;
                                        }
                                    });
                                } else {
                                    this.submitButton = ClrLoadingState.DEFAULT;
                                }
                            });
                        } else {
                            this.submitButton = ClrLoadingState.DEFAULT;
                        }
                    });
                }
            });
        } else {
            this.saveCodiceClienteTerminale(terminale.id, cliente.id, formValue.codiceInternoCliente).subscribe(id => {
                if (!isNaN(id)) {
                    this.saveApplicatore(terminale.id, formValue).subscribe(applicatore => {
                        if (applicatore && this.file) {
                            this.uploadFileSpecifiche(applicatore.id).subscribe(() => {
                                this.saveOrdine(cliente.id, applicatore.codice);
                            });
                        } else if (applicatore) {
                            this.saveOrdine(cliente.id, applicatore.codice);
                        } else {
                            this.saveOrdine(cliente.id, this.applicatoreOriginale);
                        }
                    });
                } else {
                    this.submitButton = ClrLoadingState.DEFAULT;
                }
            });
        }
    }
    
    saveCostruttore(costruttore: Costruttore, nome: string): Observable<number> {
        if (costruttore) {
            return of(costruttore.id);
        } else {
            return this.costruttoriService.create(new CostruttoreCommand(nome));
        }
    }
    
    saveCliente(cliente: Cliente, ragioneSociale: string, referente: string): Observable<number> {
        if (cliente) {
            return of(cliente.id);
        } else {
            const dto: ClienteDTO = new ClienteDTO();

            dto.cliente = new ClienteCommand(ragioneSociale, referente);
            dto.indirizzi = [];
            return this.clientiService.create(dto);
        }
    }
    
    saveTerminale(terminale: Terminale, codice: string, costruttoreId: number, clienteId: number): Observable<number> {
        if (terminale) {
            return of(terminale.id);
        } else {
            return this.terminaliService.create(new TerminaleCommand(codice, costruttoreId));
        }
    }
    
    saveCodiceClienteTerminale(terminaleId: number, clienteId: number, codice: string): Observable<number> {
        if (codice) {
            return this.terminaliService.addClienteCodiceTerminale(new ClienteCodiceTerminaleCommand(codice, terminaleId, clienteId));
        } else {
            return of(0);
        }
    }
    
    saveApplicatore(terminaleId: number, formValue: any): Observable<Applicatore> {
        if (this.id == 'new' || formValue.applicatore != this.applicatoreOriginale) {
            return this.applicatoriService.createApplicatore(new ApplicatoreCommand(
                formValue.applicatore,
                terminaleId,
                formValue.modello,
                formValue.tipo,
                formValue.modelloPressa,
                formValue.marcaPressa,
                Number(formValue.altezzaTaraturaPressa),
                Number(formValue.corsaPressa),
                formValue.specificheAggraffatura,
                formValue.contaCicli,
                formValue.ghieraCentesimale,
                formValue.taglioBandella,
                formValue.aggraffaturaGommino,
                formValue.terminaleIncudine,
                formValue.disposizioneDoppioCavo,
                formValue.aggraffaturaConduttore,
                formValue.aggraffaturaIsolante,
            ));
        } else {
            return of(null);
        }
    }
    
    uploadFileSpecifiche(applicatoreId: number): Observable<number> {
        let fileName = "Specifiche-" + applicatoreId;
        
        return this.applicatoriService.aggiungiDocumento(applicatoreId.toString(), this.globals.DOC_SPECIFICHE, this.file, fileName);
    }

    saveOrdine(clienteId: number, snApplicatore: string): void {
        this.submitButton = ClrLoadingState.LOADING;
        const formValue = this.form.getRawValue();
        const dto: OrdineDTO = new OrdineDTO();
        const command: OrdineCommand = new OrdineCommand(clienteId, 
            this.utilsService.fieldToDate(formValue.dataEmissione),
            formValue.numeroOrdine,
            formValue.referente,
            formValue.costruttore,
            formValue.terminale,
            formValue.codiceInternoCliente,
            snApplicatore,
            formValue.nuovo,
            formValue.tipo,
            formValue.modello,
            formValue.modelloPressa,
            formValue.marcaPressa,
            Number(formValue.altezzaTaraturaPressa),
            Number(formValue.corsaPressa),
            formValue.specificheAggraffatura,
            formValue.contaCicli,
            formValue.ghieraCentesimale,
            formValue.taglioBandella,
            formValue.aggraffaturaGommino,
            formValue.terminaleIncudine,
            formValue.disposizioneDoppioCavo,
            formValue.aggraffaturaConduttore,
            formValue.aggraffaturaIsolante,
            formValue.note,
            formValue.materialeOrdine
        );

        dto.ordine = command;
        dto.test = this.createTestCommand();
        dto.toDelete = this.toDelete;
        if (!this.id || this.id === 'new') {
            this.ordiniService.create(dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                this.createForm();
                this.initializeFormFields(null);
                if (res) {
                    this.alertMessage = 'Ordine creato!';
                    this.alertClosed = false;
                    this.router.navigate(['ordini']);
                }
            });
        } else {
            this.ordiniService.update(this.id, dto).subscribe((res) => {
                this.submitButton = ClrLoadingState.DEFAULT;
                if (!res) {
                    this.alertMessage = 'Ordine aggiornato!';
                    this.alertClosed = false;
                    this.router.navigate(['ordini']);
                }
            });
        }
        console.log(formValue);
    }

    createTestCommand(): Array<TestCommand> {
        return this.tests.controls.filter(control => control.getRawValue().id == 0).map(control => {
            const fv = control.getRawValue();
            let ic = new TestCommand();
            
            ic.sezioneConduttore = Number(fv.sezioneConduttore);
            ic.diametroIsolante = Number(fv.diametroIsolante);
            ic.micrografia = fv.micrografia;
            ic.capabilityAltezza = fv.capabilityAltezza;
            ic.capabilityForza = fv.capabilityForza;
            return ic;
        });
    }

    createForm(): void {
        const formGroup = {
            dataEmissione: ['', [Validators.required]],
            cliente: [{value: '', disabled: true}, [Validators.required]],
            numeroOrdine: ['', [Validators.required]],
            referente: [{value: '', disabled: true}],
            terminale: ['', [Validators.required]],
            costruttore: [''],
            codiceInternoCliente: [''],
            applicatore: [''],
            nuovo: [{value: true, disabled: true}],
            modello: ['', [Validators.required]],
            tipo: ['', [Validators.required]],
            modelloPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            marcaPressa: ['', [Validators.required, Validators.maxLength(255), Validators.pattern(this.globals.NO_SPACES_ONLY)]],
            altezzaTaraturaPressa: [{ value: '' }],
            corsaPressa: [{ value: '' }],
            specificheAggraffatura: ['', [Validators.required]],
            contaCicli: [{ value: false }],
            ghieraCentesimale: [{ value: false }],
            taglioBandella: [{ value: false }],
            aggraffaturaGommino: [{ value: false }],
            terminaleIncudine: [{ value: false }],
            disposizioneDoppioCavo: [''],
            aggraffaturaConduttore: ['', [Validators.required]],
            aggraffaturaIsolante: ['', [Validators.required]],
            note: [{ value: '' }],
            materialeOrdine: ['', [Validators.required]],
            tests: this.formBuilder.array([])
        };
        this.form = this.formBuilder.group(formGroup);
    }

    get tests() {
        return this.form.controls["tests"] as FormArray;
    }
    
    addTestField(): void {
        const item = this.formBuilder.group({
            id: 0,
            sezioneConduttore: ['', Validators.required],
            diametroIsolante: ['', Validators.required],
            micrografia: [false],
            capabilityAltezza: [false],
            capabilityForza: [false],
            fileMicrografiaName: '',
            fileCapabilityAltezzaName: '',
            fileCapabilityForzaName: '',
            fileMicrografia: undefined,
            fileCapabilityAltezza: undefined,
            fileCapabilityForza: undefined
        });
        
        this.tests.push(item);
    }

    addTest(test: Test, tests: Array<any>): void {
        const testValues = {
            id: test.id,
            sezioneConduttore: test.sezioneConduttore,
            diametroIsolante: test.diametroIsolante,
            micrografia: test.micrografia,
            capabilityAltezza: test.capabilityAltezza,
            capabilityForza: test.capabilityForza,
            fileMicrografiaName: test.fileMicrografiaName || '',
            fileCapabilityAltezzaName: test.fileCapabilityAltezzaName || '',
            fileCapabilityForzaName: test.fileCapabilityForzaName || '',
            fileMicrografia: null,
            fileCapabilityAltezza: null,
            fileCapabilityForza: null
        };
        
        tests.push(testValues);
    }

    initializeFormFields(ordine: Ordine): void {
        let formValues;

        if (ordine) {
            this.applicatoreOriginale = ordine.applicatore;
            formValues = {
                dataEmissione: this.utilsService.dateToField(ordine.dataEmissione),
                cliente: ordine.cliente.ragioneSociale,
                numeroOrdine: ordine.numeroOrdine,
                referente: ordine.cliente.referente ? ordine.cliente.referente : '',
                terminale: ordine.terminale,
                costruttore: ordine.costruttore,
                codiceInternoCliente: ordine.codiceInternoCliente,
                applicatore: ordine.applicatore,
                nuovo: ordine.nuovo,
                modello: ordine.modelloApplicatore,
                tipo: ordine.tipoApplicatore,
                modelloPressa: ordine.modelloPressa,
                marcaPressa: ordine.marcaPressa,
                altezzaTaraturaPressa: ordine.altezzaTaraturaPressa,
                corsaPressa: ordine.corsaPressa,
                specificheAggraffatura: ordine.specificheAggraffatura.toString(),
                contaCicli: ordine.contaCicli,
                ghieraCentesimale: ordine.ghieraCentesimale,
                taglioBandella: ordine.taglioBandella,
                aggraffaturaGommino: ordine.aggraffaturaGommino,
                terminaleIncudine: ordine.terminaleIncudine,
                disposizioneDoppioCavo: ordine.disposizioneDoppioCavo.toString(),
                aggraffaturaConduttore: ordine.aggraffaturaConduttore.toString(),
                aggraffaturaIsolante: ordine.aggraffaturaIsolante.toString(),
                note: ordine.note,
                materialeOrdine: ordine.materialeOrdine,
                tests: []
            };
            ordine.test.forEach(t => this.addTest(t, formValues.tests));
        } else {
            formValues = {
                dataEmissione: this.utilsService.dateToField(new Date()),
                cliente: '',
                numeroOrdine: '',
                referente: '',
                terminale: '',
                costruttore: '',
                codiceInternoCliente: '',
                applicatore: '',
                nuovo: true,
                modello: '0',
                tipo: '0',
                modelloPressa: '',
                marcaPressa: '',
                altezzaTaraturaPressa: '',
                corsaPressa: '',
                specificheAggraffatura: '0',
                contaCicli: false,
                ghieraCentesimale: false,
                taglioBandella: false,
                aggraffaturaGommino: false,
                terminaleIncudine: false,
                disposizioneDoppioCavo: '0',
                aggraffaturaConduttore: '0',
                aggraffaturaIsolante: '0',
                note: '',
                materialeOrdine: '0',
                tests: []
            };
        }
        this.form.setValue(formValues);
    }

    onSelectCliente(): void {
        const formValues = {
            cliente: this.selectedCliente ? this.selectedCliente.ragioneSociale : '',
            referente: this.selectedCliente ? this.selectedCliente.referente : ''
        };

        this.form.patchValue(formValues);
        this.currentCliente = this.selectedCliente;
        this.setCodiceTerminale();
        this.showSceltaClienteModal = false;
        this.form.markAsDirty();
    }

    setCodiceTerminale(): void {
        if (this.selectedCliente && this.selectedTerminale) {
            this.clientiService.getCodiceTerminale(this.selectedCliente.id.toString(), this.selectedTerminale.id.toString()).subscribe(ct => {
                if (ct) {
                    const formValues = {
                        codiceInternoCliente: ct.codice
                    };

                    this.form.patchValue(formValues);
                }
            });
        }
    }
    
    onShowSceltaClienteModal(): void {
        this.showSceltaClienteModal = true;
    }

    onSelectCostruttore(): void {
        const formValues = {
            costruttore: this.selectedCostruttore ? this.selectedCostruttore.nome : ''
        };

        this.form.patchValue(formValues);
        this.currentCostruttore = this.selectedCostruttore;
        this.showSceltaCostruttoreModal = false;
        this.form.markAsDirty();
    }
    
    onShowSceltaCostruttoreModal(): void {
        this.showSceltaCostruttoreModal = true;
    }

    onSelectApplicatore(): void {
        this.selectedTerminale = this.selectedApplicatore.terminale;
        
        const formValues = {
            applicatore: this.selectedApplicatore ? this.selectedApplicatore.codice.toString() : '',
            terminale: this.selectedTerminale ? this.selectedTerminale.codice : '',
            nuovo: false,
            modello: this.selectedApplicatore ? this.selectedApplicatore.modelloApplicatore.toString() : '',
            tipo: this.selectedApplicatore ? this.selectedApplicatore.tipoApplicatore.toString() : '',
            modelloPressa: this.selectedApplicatore ? this.selectedApplicatore.modelloPressa : '',
            marcaPressa: this.selectedApplicatore ? this.selectedApplicatore.marcaPressa : '',
            altezzaTaraturaPressa: this.selectedApplicatore ? this.selectedApplicatore.altezzaTaraturaPressa : '',
            corsaPressa: this.selectedApplicatore ? this.selectedApplicatore.corsaPressa : '',
            specificheAggraffatura: this.selectedApplicatore ? this.selectedApplicatore.specificheAggraffatura.toString() : '',
            contaCicli: this.selectedApplicatore ? this.selectedApplicatore.contaCicli : '',
            ghieraCentesimale: this.selectedApplicatore ? this.selectedApplicatore.ghieraCentesimale : '',
            taglioBandella: this.selectedApplicatore ? this.selectedApplicatore.taglioBandella : '',
            aggraffaturaGommino: this.selectedApplicatore ? this.selectedApplicatore.aggraffaturaGommino : '',
            terminaleIncudine: this.selectedApplicatore ? this.selectedApplicatore.terminaleIncudine : '',
            disposizioneDoppioCavo: this.selectedApplicatore ? this.selectedApplicatore.disposizioneDoppioCavo.toString() : '',
            aggraffaturaConduttore: this.selectedApplicatore ? this.selectedApplicatore.aggraffaturaConduttore.toString() : '',
            aggraffaturaIsolante: this.selectedApplicatore ? this.selectedApplicatore.aggraffaturaIsolante.toString() : ''
        };

        this.currentTerminale = this.selectedTerminale;
        this.setCodiceTerminale();
        this.form.patchValue(formValues);
        this.form.get('nuovo').enable();
        this.currentApplicatore = this.selectedApplicatore;
        this.showSceltaApplicatoreModal = false;
        this.form.markAsDirty();
    }
    
    onShowSceltaApplicatoreModal(): void {
        this.showSceltaApplicatoreModal = true;
    }

    onSelectTerminale(): void {
        const formValues = {
            costruttore: this.selectedTerminale && this.selectedTerminale.costruttore ? this.selectedTerminale.costruttore.nome : '',
            terminale: this.selectedTerminale ? this.selectedTerminale.codice : ''
        };

        this.form.patchValue(formValues);
        this.currentTerminale = this.selectedTerminale;
        this.setCodiceTerminale();
        this.showSceltaTerminaleModal = false;
        this.form.markAsDirty();
    }
    
    onShowSceltaTerminaleModal(): void {
        this.showSceltaTerminaleModal = true;
    }
    
    refreshClienti(state: ClrDatagridStateInterface): void {
        this.refresh(state, this.clientiService, 'Cliente');
    }
    
    refreshCostruttori(state: ClrDatagridStateInterface): void {
        this.refresh(state, this.costruttoriService, 'Costruttore');
    }
    
    refreshApplicatori(state: ClrDatagridStateInterface): void {
        this.refresh(state, this.applicatoriService, 'Applicatore');
    }
    
    refreshTerminali(state: ClrDatagridStateInterface): void {
        this.refresh(state, this.terminaliService, 'Terminale');
    }
    
    refresh(state: ClrDatagridStateInterface, service: BaseService<AbstractBean>, entity: string): void {
        if (state && state.page) {
            if (state.page.current < 0) {
                state.page.current = 0;
            }

            const fields: Array<string> = new Array<string>();
            const operators: Array<string> = new Array<string>();
            const values: Array<any> = new Array<any>();

            if (state.filters) {
                for (const filter of state.filters) {
                    if (filter.property) {
                        fields.push(filter.property);
                        operators.push('like');
                        values.push('%' + filter.value + '%');
                    }
                }
            }
            service.getAll(
                state.page.current ? state.page.current - 1 : 0,
                state.page.size ? state.page.size : 10,
                fields,
                operators,
                values,
                state.sort ? state.sort.by.toString() : null,
                state.sort ? (state.sort.reverse ? 'desc' : 'asc') : null
            ).subscribe((result: QueryResult<AbstractBean>) => {
                if (entity == 'Cliente') {
                    this.clienti = <Array<Cliente>> result.objects;
                    this.totalClienti = result.count;
                } else if (entity == 'Costruttore') {
                    this.costruttori = <Array<Costruttore>> result.objects;
                    this.totalCostruttori = result.count;
                } else if (entity == 'Applicatore') {
                    this.applicatori = <Array<Applicatore>> result.objects;
                    this.totalApplicatori = result.count;
                } else if (entity == 'Terminale') {
                    this.terminali = <Array<Terminale>> result.objects;
                    this.totalTerminali = result.count;
                }
            });
        }
    }

    onNuovoChange(event): void {
        const checked = event.target.checked;
        
        if (checked) {
            const formValues = {
                applicatore: '',
                modello: '0',
                tipo: '0',
                modelloPressa: '',
                marcaPressa: '',
                altezzaTaraturaPressa: '',
                corsaPressa: '',
                specificheAggraffatura: '0',
                contaCicli: false,
                ghieraCentesimale: false,
                taglioBandella: false,
                aggraffaturaGommino: false,
                terminaleIncudine: false,
                disposizioneDoppioCavo: '0',
                aggraffaturaConduttore: '0',
                aggraffaturaIsolante: '0',
                note: ''
            };

            this.form.get('nuovo').disable();
            this.form.patchValue(formValues);
            this.currentApplicatore = undefined;
            this.form.markAsDirty();
        }
    }
    
    get altezzaStandard(): boolean {
        const formValue = this.form.getRawValue();
        
        return formValue.altezzaTaraturaPressa == '' || Number(formValue.altezzaTaraturaPressa) == this.ALTEZZA_STANDARD;
    }
    
    get corsaStandard(): boolean {
        const formValue = this.form.getRawValue();
        
        return formValue.corsaPressa == '' || Number(formValue.corsaPressa) == this.CORSA_STANDARD;
    }
    
    aggiungiTest(): void {
        this.addTestField();
    }
    
    eliminaTest(i: number): void {
        let s: Observable<any> = this.tests.controls[i].getRawValue().id && this.tests.controls[i].getRawValue().id != 0 ?
            this.dialog.confirm({
                title: 'Comunicazione',
                content: 'Confermi la cancellazione?',
                acceptText: 'Sì',
                cancelText: 'Annulla',
                acceptType: 'warning',
                iconShape: null
            }) : of(true);
            
        s.subscribe((result: boolean) => {
            if (result) {
                if (this.tests.controls[i].getRawValue().id && this.tests.controls[i].getRawValue().id != 0) {
                    this.toDelete.push(this.tests.controls[i].getRawValue().id);
                }
                this.tests.removeAt(i);
                this.form.markAsDirty();
            }
        });
    }

    onFileChange(event: any) {
        this.file = event.target.files[0];
    }

    onDownloadDocumento(id: number, tipo: string) {
        this.testService.getDocumentDownloadUrl(id.toString(), tipo).subscribe((url) => {
            if (url) {
                window.open(url);
            }
        });
    }
    
    onFileTestChange(test: FormControl, tipo: string, event: any) {
        let values = {};

        if (tipo === this.globals.DOC_MICROGRAFIA) {
            values['fileMicrografia'] = event.target.files[0];
        } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            values['fileCapabilityAltezza'] = event.target.files[0];
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            values['fileCapabilityForza'] = event.target.files[0];
        }
        test.patchValue(values);
    }
    
    uploadFile(test: FormControl, tipo: string): void {
        let fileName = tipo + "-" + test.value.id;
        let file: File;
        
        if (tipo === this.globals.DOC_MICROGRAFIA) {
            file = test.value.fileMicrografia;
        } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            file = test.value.fileCapabilityAltezza;
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            file = test.value.fileCapabilityForza;
        }
        this.testService.aggiungiDocumento(test.value.id, tipo, file, fileName).subscribe(() => {
            if (tipo === this.globals.DOC_MICROGRAFIA) {
                test.value.fileMicrografiaName = fileName;
            } else if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
                test.value.fileCapabilityAltezzaName = fileName;
            } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
                test.value.fileCapabilityForzaName= fileName;
            }
        });
    }

    generateFile(test: FormControl, tipo: string) {
        if (tipo === this.globals.DOC_CAPABILITY_ALTEZZA) {
            this.showDialogCapabilityAltezza = true;
        } else if (tipo === this.globals.DOC_CAPABILITY_FORZA) {
            this.showDialogCapabilityForza = true;
        }
    }
    
}
